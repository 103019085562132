@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: large;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
}

.navbar {
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 600;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #007bff !important;
}

.btn-get-started {
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-decoration: none;
}

.btn-get-started:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.header-img {
  text-align: right;
}

.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* Service Page Styles */
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Contact Page Styles */
.contact-form input,
.contact-form textarea {
  border: 1px solid #ced4da;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

/* Recordings Page Styles */
.list-group-item {
  transition: background-color 0.3s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-brand {
    font-size: 1.3rem;
  }

  h1 {
    font-size: 2rem;
  }

  .header-img {
    margin-top: 2rem;
  }
}